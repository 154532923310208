import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/aboutUs",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/loginView.vue"),
  },

  {
    path: "/homePage",
    name: "homePage",
    redirect: "/indexView",
    component: () => import("@/views/homePage/homeIndex.vue"),
    children: [
      {
        path: "/personMessage",
        name: "personMessage",
        component: () => import("@/views/personMessage/messageIndex.vue"),
      },
      {
        path: "/indexView",
        name: "indexView",
        component: () => import("@/views/homePage/indexView.vue"),
      },
      {
        path: "/getStart",
        name: "getStart",
        component: () => import("@/views/getStart/getStartIndex.vue"),
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import("@/views/product/productRecommend.vue"),
      },
      {
        path: "/electricTable",
        name: "electricTable",
        component: () => import("@/views/electricTable/electricTable.vue"),
      },
      {
        path: "/customService",
        name: "customService",
        component: () => import("@/views/customService/serviceIndex.vue"),
      },
      {
        path: "/message",
        name: "message",
        component: () => import("@/views/message/messageIndex"),
      },
    ],
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/servicePage",
    name: "servicePage",
    component: () => import("@/views/service/servicePage.vue"),
    redirect: "/feedbackProcess",
    children: [
      {
        path: "/installationReview",
        name: "installationReview",
        component: () =>
          import("@/views/service/components/installationReview.vue"),
      },
      {
        path: "/feedbackProcess",
        name: "feedbackProcess",
        component: () =>
          import("@/views/service/components/feedbackProcess.vue"),
      },
    ],
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/login/registerView.vue"),
  },
  {
    path: "/findPwd",
    name: "forgetPwd",
    component: () => import("@/views/login/forgetPwd.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/error/notFound.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.name === from.name) {
    return false;
  }
  next();
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requireAuth) &&
    to.path != "/aboutUs"
  ) {
    // 判断该路由是否需要登录权限
    if (sessionStorage.electricToken) {
      // 判断当前的token是否存在 ； 登录存入的token
      next();
    } else {
      next({
        path: "/login",
        // 将跳转的路由path作为参数，登录成功后跳转到该路由
      });
    }
  } else {
    next();
  }
});
export default router;
