function PayBill(param) {
  // 必须要存进localstorage，否则会报错，显示不完全
  window.localStorage.removeItem("callbackHTML");
  window.localStorage.setItem("callbackHTML", param);
  // 读取本地保存的html数据，使用新窗口打开
  var newWin = window.open("", "_blank");
  // 读取本地保存的html数据，使用当前窗口打开
  // var newWin = window.open('', '_self')
  newWin.document.write(localStorage.getItem("callbackHTML"));
  // 关闭输出流
  newWin.document.close();
}

export default {
  install: function (Vue) {
    Vue.prototype.pay_bill = (param) => PayBill(param);
  },
};
